

export function useTextHelpers() {

    function removeGreekAccents(str: string): string {
        const accentMap = {
            'ά': 'α',
'έ': 'ε',
'ή': 'η',
'ί': 'ι',
'ό': 'ο',
'ύ': 'υ',
'ώ': 'ω',
            'Ά': 'Α',
'Έ': 'Ε',
'Ή': 'Η',
'Ί': 'Ι',
'Ό': 'Ο',
'Ύ': 'Υ',
'Ώ': 'Ω',
            'ϊ': 'ι',
'ϋ': 'υ',
'ΐ': 'ι',
'ΰ': 'υ',
        };

        return str.split('').map(char => accentMap[char] || char).join('');
    }

    return {
        removeGreekAccents,
    };
    
}
